<template>
  <div class="pages">
    <van-sticky style="background-color: white">
      <div class="nav-bar">
        <div @click="newAppBack" class="nav-bar-left"><van-icon name="arrow-left" /></div>
        <div
          @click="
            showSelect = !showSelect
            showFilter = false
          "
          :class="{ active: showSelect }"
          class="nav-bar-title f18 fw6 row-center-center">
          {{ navList[selectTabType].name }} <van-icon class="arrow-title fw6" name="arrow-down" />
        </div>
        <div class="nav-bar-right"></div>
        <div class="popup-box" :class="{ active: showSelect }">
          <div @click="selectTab(index)" v-for="(item, index) in navList" :key="index" :class="{ active: index === selectTabType }" class="row-start-center">{{ item.name }}</div>
        </div>
      </div>
      <div class="filter row-between-center" v-show="navList[selectTabType].type === 'PUBLIC'">
        <div class="menu-box row-start-center">
          <div v-for="(item, index) in filterList" :class="{ active: filterIndex === index && showFilter }" :key="index" @click="openFilter(index)" class="menu-item">
            <p :class="['ellipsis', item.selectName ? 'fw6' : '']">{{ item.selectName || item.name }}</p>
            <van-icon class="menu-arrow" name="arrow-down" />
          </div>
        </div>

        <div v-show="userId_ && navList[selectTabType].type === 'PUBLIC'" @click="getRecommend" :class="['system-recommend row-center-center f14 fw6', searchForm.sysRecommend ? 'active' : '']"><img :src="`https://img.chaolu.com.cn/PAD/icon/recommend_${searchForm.sysRecommend ? 'b' : 'a'}.png`" alt="" /> 系统推荐</div>

        <div class="popup-box" :class="{ active: showFilter }">
          <i></i>
          <div class="col-between-start filter-box">
            <div class="filter-list">
              <h5>选择{{ filterList[filterIndex].name }}</h5>
              <div class="row-start-center filter-item">
                <div @click="selectFilter(index)" :class="['row-center-center', item.active ? 'active fw6' : '']" v-for="(item, index) in filterList[filterIndex].item" :key="item.type">
                  <span class="ellipsis">{{ item.name }}</span>
                </div>
              </div>
            </div>
            <div class="filter-btn row-between-center fw6">
              <div @click="submitFilter(true)" class="row-center-center">重置</div>
              <div @click="submitFilter()" class="row-center-center active">确定</div>
            </div>
          </div>
        </div>
      </div>
    </van-sticky>
    <div v-if="caseList.length" class="list">
      <van-pull-refresh class="page-refresh" v-model="refreshing" @refresh="getCaseList(false)">
        <van-list class="flex flex-wrap" v-model="loading" :finished="finished" finished-text="" @load="getCaseList" :immediate-check="false">
          <div class="item" v-for="(item, index) in caseList" :key="index" @click="imagePreview(index)">
            <img :src="item.caseAbridgePhoto" alt="" />
            <div @click.stop="collect(item)" class="collect row-center-center">
              <img :src="`https://img.chaolu.com.cn/PAD/icon/star-${item.hasCollection ? '2' : '1'}.png`" alt="">
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-else class="empty">
      <img src="https://img.chaolu.com.cn/PAD/icon/empty.png" alt="" />
      <p>暂无案例图片</p>
    </div>
    <van-overlay
      :show="showSelect || showFilter"
      @click="
        showSelect = false
        showFilter = false
      " />

    <van-image-preview v-model="previewObj.show" :start-position="curIndex" :images="previewList" :loop="false" @change="previewChange">
      <template v-slot:index>
        <div class="row-between-center f20 fw6">
          <van-icon @click="previewObj.show = false" name="cross" size="32" />
          <p>{{ `${curIndex + 1}/${caseList.length}` }}</p>
          <div @click.stop="collect(caseList[curIndex])" class="collect row-center-center">
            <img :src="`https://img.chaolu.com.cn/PAD/icon/star-${caseList[curIndex].hasCollection ? '2' : '1'}.png`" alt="">
          </div>
        </div>
      </template>
    </van-image-preview>
  </div>
</template>

<script>
import { initBack, newAppBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import { getAuthorization } from '@/views/ipad/ipadMethod'
import userMixin from '@/mixin/userMixin'
export default {
  mixins: [userMixin],
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,

      navList: [
        { name: '超鹿案例库', type: 'PUBLIC' },
        { name: '我的案例库', type: 'PERSONAL' },
        { name: '我的收藏', type: 'COLLECTION' },
      ],
      filterList: [
        {
          name: '性别',
          item: [
            { name: '男', type: 1 },
            { name: '女', type: 0 },
          ],
        },
        { name: '运动时长', item: [] },
        { name: '运动需求', item: [] },
      ],
      showSelect: false,
      showFilter: false,
      selectTabType: 0,
      filterIndex: 0,
      searchForm: {
        sexList: [],
        durationList: [],
        requirementIdList: [],
        size: 12,
        current: 1,
        teacherId: '',
        sysRecommend: false,
      },
      caseList: [],
      previewList: [],

      previewObj: {
        show: false,
        index: 0,
      },
      curIndex: 0,
    }
  },
  computed: {
    initDuration() {
      let d = getParam().duration
      if (!d) return ''
      d = d * 1
      switch (d) {
        case 1:
        case 2:
          return '1-2个月'
        case 3:
        case 4:
          return '3-4个月'
        case 5:
        case 6:
          return '5-6个月'
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
          return '7-12个月'
        default:
          return '12个月以上'
      }
    }
  },
  async created() {
    try {
      window.webkit.messageHandlers.hideNavigationBar.postMessage({ hideNavigationBar: '1', title: '' })
    } catch (e) {
      console.log(e)
    }
    // 初始化
    await this.$getAllInfo(['teacherId'])
    initBack()
    this.userId_ = getParam().userId

    this.searchForm.teacherId = this.teacherId || '884746380692684800'
    this.searchForm.sexList = getParam().sex ? [Number(getParam().sex)] : []
    this.searchForm.requirementIdList = getParam().requirementId ? [Number(getParam().requirementId)] : []

    this.authorization = await getAuthorization()
    this.authorization =
      this.authorization ||
      'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJqaWFuZ2pyIiwiYXBwTmFtZSI6IumAmueUqOezu-e7nyIsInNjb3BlIjpbImFsbCJdLCJhcHBJZCI6NCwib2xkVXNlcklkIjoiMTA4MjYyODQ0NjY5OTM4ODkyOCIsImV4cCI6MTcyNTI4ODI4NCwidXNlcklkIjo4OSwiYXV0aG9yaXRpZXMiOlsiW10iXSwianRpIjoiNzE1YWM1MDQtN2RkNS00OGQ0LWE4ZGItYWJhNTk3NjhiZTAyIiwiY2xpZW50X2lkIjoiNCIsInVzZXJuYW1lIjoiamlhbmdqciJ9.Jf12PI40boyeK7pqLtnzyckzppcx7tTGSz3oW21A2qI'

    await this.getAllDict()
    await this.getPerSportRequirementList()
    this.initData()
  },
  methods: {
    initData() {
      let sex = getParam().sex
      if (sex === '1') {
        this.$set(this.filterList[0].item[0], 'active', true)
        this.filterList[0].selectName = '男'
      } else if (sex === '0') {
        this.$set(this.filterList[0].item[1], 'active', true)
        this.filterList[0].selectName = '女'
      }
      this.dealForm()
      this.getCaseList()
    },
    newAppBack,
    getAllDict() {
      return this.$axios.post(`${this.baseURLApp}/common/getAllDict`, {}).then((r) => {
        let selectName = ''
        this.filterList[1].item = (r.data?.sportDuration || []).map((v, k) => {
          let item = { name: v, type: v }
          if (v === this.initDuration) {
            selectName = v
            item.active = true
          }
          return item
        })
        this.filterList[1].selectName = selectName
      })
    },
    getPerSportRequirementList() {
      return new Promise((resolve) => {
        this.$axios
          .post(`${this.baseURLApp}/ipad/perSportRequirement/list`, {})
          .then((r) => {
            let requirementId = getParam().requirementId ? parseInt(getParam().requirementId) : '',
              selectName = ''
            this.filterList[2].item = (r.data || []).map((v) => {
              // 处理默认参数
              let item = { name: v.name, type: v.requirementId }
              if (requirementId === v.requirementId) {
                selectName = v.name
                item.active = true
              }
              return item
            })
            this.filterList[2].selectName = selectName
          })
          .finally((r) => {
            resolve()
          })
      })
    },
    selectTab(v) {
      this.selectTabType = v
      this.showSelect = false

      this.getCaseList(false)
    },
    openFilter(v) {
      this.filterIndex = v
      this.showFilter = true
    },
    selectFilter(index) {
      this.$set(this.filterList[this.filterIndex].item[index], 'active', !this.filterList[this.filterIndex].item[index].active)
      this.filterList[this.filterIndex].selectName = ''
      this.filterList[this.filterIndex].item.forEach((item) => {
        if (item.active) {
          let name = this.filterList[this.filterIndex].selectName || ''
          this.$set(this.filterList[this.filterIndex], 'selectName', name + (name ? `/${item.name}` : `${item.name}`))
        }
      })
    },
    submitFilter(reset) {
      if (reset) {
        this.filterList[this.filterIndex].selectName = ''
        this.filterList[this.filterIndex].item.forEach((item) => {
          this.$set(item, 'active', false)
        })
      } else {
        this.dealForm()
        console.log(this.searchForm)
        this.showFilter = false
      }
      this.getCaseList(false)
    },
    getCaseList(noRefreshing = true) {
      this.loading = true;
      this.refreshing = !noRefreshing;
      if (this.refreshing) {
        this.caseList = []
        this.previewList = []
        this.searchForm.current = 1
        this.finished = false
      }

      this.$axios.post(`${this.baseURLApp}/ipad/teacherusercase/publicCasePage`, {
        authorization: this.authorization,
        type: this.navList[this.selectTabType].type,
        userId: this.userId_,
        ...this.searchForm,
      })
      .then((r) => {
        this.searchForm.current++
        this.caseList = this.caseList.concat(r.data.records)
        this.loading = false
        this.refreshing = false
        if (r.data.records.length < this.searchForm.size) {
          this.finished = true
        }
        const imgList = r.data.records.map((item) => item.casePhoto)
        this.previewList = this.previewList.concat(imgList)
      })
    },
    getRecommend() {
      this.searchForm.sysRecommend = !this.searchForm.sysRecommend
      this.getCaseList(false)
    },
    dealForm() {
      const freezeKey = ['sexList', 'durationList', 'requirementIdList']
      freezeKey.map((key, index) => {
        this.searchForm[key] = Object.freeze(this.filterList[index].item.filter((v) => v.active).map((v) => v.type))
      })
    },
    imagePreview(index) {
      console.log(this.caseList[index].casePhoto, this.caseList[index].caseAbridgePhoto);

      this.curIndex = index
      this.previewObj = {
        show: true,
      }
    },
    previewChange(i) {
      this.curIndex = i
    },
    // 收藏
    collect(item) {
      this.$axios.post(`${this.baseURLApp}/ipad/teacherusercase/collectionTeacherUserCase`, {
        caseId: item.caseId,
        collection: !item.hasCollection,
        teacherId: this.searchForm.teacherId
      })
      .then((r) => {
        this.$set(item, 'hasCollection', !item.hasCollection)
      })
    },
  },
}
</script>
<style scoped lang="less">
.pages {
  background: #f9fafb;
  min-height: 100vh;
}
.nav-bar {
  width: 750px;
  //height: 68px;
  background: white;
  display: flex;
  align-items: center;
  padding: calc(20px + env(safe-area-inset-top)) 20px 15px;
  box-sizing: border-box;
  position: relative;
  z-index: 110;

  .popup-box {
    position: absolute;
    box-sizing: border-box;
    top: 64px;
    left: 0;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    height: 0;
    transition: height 0.3s;
    z-index: 100;

    &.active {
      height: auto;
    }
    div {
      width: 750px;
      height: 78px;
      background: #ffffff;
      padding: 0 20px;
      font-size: 18px;
      color: #242831;

      &.active {
        color: #267dff;
        background: rgba(38, 125, 255, 0.05);
      }
    }
  }
  .nav-bar-left {
    width: 120px;
    font-size: 24px;
  }
  .nav-bar-title {
    color: #000000;
    text-align: center;
    width: 470px;
    .arrow-title {
      margin-left: 8px;
      color: #242831;
      transition: transform 0.3s;
    }
    &.active {
      .arrow-title {
        transform: rotateZ(180deg);
      }
    }
  }
  .nav-bar-right {
    width: 120px;
  }
}
.list {
  padding: 0 19px 30px;
  .item {
    margin-top: 20px;
    margin-right: 20px;
    margin: 20px 20px 0 0;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0;
    }
    img {
      display: block;
      width: 224px;
      height: 290px;
      border-radius: 8px 8px 8px 8px;
      object-fit: cover;
    }
    .collect {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 39px;
      height: 39px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      img {
        width: 23px;
        height: 23px;
      }
    }
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
  img {
    width: 143px;
    height: 143px;
  }
  p {
    margin-top: 28px;
    line-height: 18px;
    color: #3c454e;
    font-size: 18px;
  }
}
.filter {
  background-color: white;
  height: 82px;
  position: relative;
  z-index: 100;
  box-sizing: border-box;
  padding: 0 20px;
  .system-recommend {
    padding: 0 14px;
    height: 37px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dddddd;
    &.active {
      background: #267dff;
      border: 1px solid #267dff;
      color: #fff;
    }
    img {
      width: 18px;
      margin: 0 4px 0 0;
    }
  }
  .menu-box {
    h4 {
      font-size: 18px;
      color: #242831;
      img {
        width: 21px;
        height: 21px;
      }
    }
    .menu-item {
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #dddddd;
      padding: 13px 10px 13px 16px;
      font-size: 14px;
      color: #242831;
      line-height: 14px;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
      & > p {
        max-width: 105px;
      }
      .menu-arrow {
        margin-left: 14px;
        transition: transform 0.3s;
        transform: rotateZ(0deg);
      }
      &.active {
        .menu-arrow {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  .popup-box {
    position: absolute;
    box-sizing: border-box;
    top: 82px;
    left: 0;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    height: 0;
    transition: height 0.4s;
    z-index: 99;
    & > i {
      width: 100%;
      height: 1px;
      background-color: #eee;
      display: block;
      margin-bottom: 12px;
    }
    &.active {
      height: 340px;
    }
    .filter-box {
      height: 327px;
    }
    .filter-list {
      width: 750px;
      //height: 78px;
      background: #ffffff;
      padding: 0 20px;
      //font-size: 18px;
      color: #242831;
      h5 {
        line-height: 16px;
        font-size: 16px;
        color: #242831;
        padding: 12px 0 2px;
      }
      .filter-item {
        max-height: 195px;
        overflow-y: auto;
        flex-wrap: wrap;
        div {
          width: 162px;
          height: 47px;
          border: 1px solid #eee;
          border-radius: 4px;
          color: #242831;
          font-size: 14px;

          margin-top: 18px;
          margin-right: 20px;
          flex-shrink: 0;
          padding: 0 8px;
          box-sizing: border-box;
          background-color: #f9fafb;
          &.active {
            background: #ffde00;
            border-color: #ffde00;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }
    .filter-btn {
      padding: 0 20px 20px;
      div {
        width: 346px;
        height: 55px;
        border-radius: 6px;
        border: 1px solid #9aa1a9;
        font-size: 18px;
        &.active {
          border-color: #ffde00;
          background: #ffde00;
        }
      }
    }
  }
}

/deep/.van-image-preview__index {
  width: 100%;
  padding: 0 24px;
  left: 0;
  transform: none;
  box-sizing: border-box;
  .collect {
    width: 62px;
    height: 62px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    img {
      width: 36px;
    }
  }
}
</style>
